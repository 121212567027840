<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block pb-0 pb-md-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">Settings</div>
        </portal>
      </div>
    </div>
  
    <AccountSetup/>

    <AccountDocuments class="mt-15"/>
  
    <DeleteAccount class="mt-15" v-if="false"/>
  </v-container>
</template>

<script>
import AccountSetup from '@/components/account/AccountSetup'
import AccountDocuments from '@/components/account/AccountDocuments'
import DeleteAccount from '@/components/account/DeleteAccount'

export default {
  name: 'AccountDetails',
  components: { AccountSetup, AccountDocuments, DeleteAccount },
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
  },
}
</script>
