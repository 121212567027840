<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description" />
      </v-col>
      
      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="5" md="3"
            >
              <span class="font-normal nameField--text">Timezone</span>
              <portal-target
                class="field-message font-normal error--text text-sm-right"
                name="settingTimezone"
              ></portal-target>
            </v-col>
            <v-col class="pb-10" cols="12" sm="7" lg="4">
              <AutocompleteField
                v-model="formDataSettings['timezone_id']"
                :items="timezones"
                item-text="display"
                item-value="id"
                :rules="rules"
                :loading="loading"
                checkBg
              >
                <template #message="{ key, message }">
                  <portal to="settingTimezone" v-if="!!message">
                    {{ message }}
                  </portal>
                </template>
              </AutocompleteField>
            </v-col>
            
            <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
              <ActionButton @click="update" :loading="loadingAction">Update</ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import { Types as authTypes } from '@/store/modules/auth'

import TextDescription from '@/components/description/TextDescription'
import AutocompleteField from '@/components/select/AutocompleteField'
import ActionButton from '@/components/buttons/ActionButton'

import { getDetailAccount, updateDetailAccount } from '@/api/setting-routes'

export default {
  name: 'AccountSetup',
  inheritAttrs: false,
  components: { TextDescription, AutocompleteField, ActionButton },
  data: () => ({
    loading: false,
    loadingAction: false,
    validateForm: true,
    description: {
      title: 'Setup your account',
      text: [],
    },
    formDataSettings: {
      'timezone_id': null,
    },
    rules: [],
    detailsAccount: null,
  }),
  mounted() {
    this.loadDetailsAccount()
  },
  computed: {
    ...mapState({
      timezones: state => state.timezones,
    }),
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    ...mapMutations({
      setAccountTimezone: authTypes.mutations.SET_ACCOUNT_TIMEZONE,
    }),
    async loadDetailsAccount() {
      this.loading = true

      const { success, payload, message } = await getDetailAccount()

      if (success) {
        this.parseSettings(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loading = false
    },
    parseSettings(settings) {
      if (!!settings) {
        Object.keys(this.formDataSettings).forEach(name => {
          this.formDataSettings[name] = settings[name]
        })
      }
    },
    async update() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.loadingAction = true

        const { success, message } = await updateDetailAccount(this.formDataSettings)

        if (success) {
          this.setAccountTimezone(this.formDataSettings['timezone_id'])
          this.$notify({ type: 'success-bg', text: message })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
  },
}
</script>
