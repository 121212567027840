<template>
  <v-card
    class="cardBg py-5 px-6 rounded drag-and-drop-card"
    flat
    outlined
    @drop.prevent="onDrop($event)"
    @dragover.prevent="setDragover"
    @dragenter.prevent="setDragover"
    @dragleave.prevent="removeDragover"
    :class="{ 'inputFocus': dragover }"
    @click="openFileInput"
  >
    <div class="height--150 d-flex flex-column justify-center align-center">
      <v-icon class="upload-big-icon" size="50">$uploadBigIcon</v-icon>
      <div class="inputColor--text pt-5">Drop your file(s) here, or click to select them.</div>
    </div>

    <div class="d-flex flex-column maxHeight--300 overflow-auto file-container">
      <div
        v-for="(file, index) in uploadedFiles"
        :key="index"
        class="mt-4 d-flex justify-space-between align-center px-6 py-4 file-content"
        @click.stop="removeFile(index)"
      >
        <div class="text-truncate font-normal">{{ file.name }}</div>

        <v-icon class="ml-4" size="18">mdi-close</v-icon>
      </div>
    </div>

    <v-file-input
      v-model="uploadedFiles"
      class="d-none"
      accept="image/png, image/jpeg, image/gif"
      ref="fileInput"
      multiple
    />
  </v-card>
</template>

<script>
export default {
  name: 'UploadFiles',
  props: {
    value: {
      type: String,
      default: null
    },
    files: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    dragover: false,
    addedFiles: [],
    timeInterval: null,
  }),
  computed: {
    uploadedFiles: {
      get() {
        return this.files
      },
      set(files) {
        this.$emit('update:files', files)
      }
    }
  },
  methods: {
    setDragover() {
      clearInterval(this.timeInterval)
      this.dragover = true
    },
    removeDragover() {
      clearInterval(this.timeInterval)

      this.timeInterval = setInterval(() => {
        this.dragover = false
      }, 100)
    },
    onDrop(e) {
      this.dragover = false;

      e.dataTransfer.files.forEach(element =>
        this.uploadedFiles.push(element)
      )

      this.updateArr()
    },
    openFileInput() {
      this.$refs.fileInput.$refs.input.click()
    },
    removeFile(index) {
      this.uploadedFiles.splice(index, 1)

      this.updateArr()
    },
    updateArr() {
      this.uploadedFiles = this.uploadedFiles.slice()
    }
  },
  beforeDestroy() {
    clearInterval(this.timeInterval)
  },
}
</script>