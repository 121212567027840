<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description" />
      </v-col>
      
      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3">
              <CancelButton @click="openConfirmDialog" :loading="loadingAction">Delete Account</CancelButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    
    <DialogConfirm
      v-model="confirmDialog"
      title="Delete account"
      subTitle=""
      message="Are you sure you want to delete your account?"
      @actionSuccess="openDeleteDialog"
    />
    
    <DialogConfirmPassword
      v-model="deleteDialog"
    />
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import CancelButton from '@/components/buttons/CancelButton'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import DialogConfirmPassword from '@/components/dialog/DialogConfirmPassword'

export default {
  name: 'DeleteAccount',
  inheritAttrs: false,
  components: { TextDescription, CancelButton, DialogConfirm, DialogConfirmPassword },
  data: () => ({
    validateForm: true,
    loadingAction: false,
    confirmDialog: false,
    deleteDialog: false,
    description: {
      title: 'Delete account',
      text: [
        'Here you can',
        'completely delete',
        'your account.',
      ],
    },
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    openConfirmDialog() {
      this.confirmDialog = true
    },
    openDeleteDialog() {
      this.confirmDialog = false
      this.deleteDialog = true
    },
  },
}
</script>
