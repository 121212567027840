<template>
  <v-card
    class="create-card cardBg py-15 px-12 rounded-20"
    :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
    flat
    outlined
  >
    <v-row class="setup-content d-block d-md-flex align-center" no-gutters>
      <v-col cols="12" md="4" lg="2">
        <TextDescription :description="description" />
      </v-col>

      <v-col cols="12" md="8" lg="10">
        <v-form ref="form" v-model="validateForm">
          <v-row no-gutters>
            <v-col
              class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
              cols="12" sm="5" md="3"
            >
              <span class="font-normal nameField--text">Files</span>
            </v-col>
            <v-col class="pb-10" cols="12" sm="7" lg="4">
              <UploadFiles :files.sync="files"/>
            </v-col>

            <v-col cols="12" sm="7" md="9" offset="0" offset-sm="5" offset-md="3" class="mt-2 mt-sm-8">
              <ActionButton @click="upload" :loading="loadingAction">Upload</ActionButton>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import TextDescription from '@/components/description/TextDescription'
import FileInput from '@/components/inputs/FileInput'
import UploadFiles from '@/components/drag-and-drop/UploadFiles'
import ActionButton from '@/components/buttons/ActionButton'

import { uploadVerificationFiles } from '@/api/setting-routes'

export default {
  name: 'AccountDocuments',
  inheritAttrs: false,
  components: { TextDescription, FileInput, UploadFiles, ActionButton },
  data: () => ({
    loading: false,
    loadingAction: false,
    validateForm: true,
    description: {
      title: 'Account Verification',
      text: [],
    },
    files: [],
    rules: [],
    detailsAccount: null,
  }),
  computed: {
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  methods: {
    async upload() {
      if (!this.files.length) {
        return
      }

      await this.validationForm()
      if (this.$refs.form.validate()) {
        if (!this.files && !this.files.length) {
          return
        }

        let formData = {}
        const config = {
          headers: { 'content-type': 'multipart/form-data' }
        }

        formData = new FormData()

        this.files.forEach(file => {
          formData.append('files[]', file, file.name)
        })

        this.loadingAction = true

        const { success, message } = await uploadVerificationFiles({ formData, config })

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.files = []
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loadingAction = false
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
  },
}
</script>