<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="560px"
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="5" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Delete account
          </div>
        </v-col>
        
        <v-col cols="12" sm="7" class="pb-8 pr-11">
          <div class="font-normal dialogConfirmQuestion--text pt-1">
            To delete account, first verify it’s you
          </div>
        </v-col>
      </v-row>
      
      <v-form class="pb-10 pt-5" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5">
            <span class="font-normal nameField--text text-sm-right">Password</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="deleteAccountPassword"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-8 pb-sm-10" cols="12" sm="7">
            <FormField
              v-model="formData.password"
              type="password"
              :rules="rules"
            >
              <template #message="{ key, message }">
                <portal to="deleteAccountPassword" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </FormField>
          </v-col>
        </v-row>
      </v-form>
      
      <div class="d-flex justify-sm-end">
        <ActionButton :loading="loading" @click="submitForm">Delete</ActionButton>
        
        <CancelButton class="ml-5 ml-sm-11" @click="closeDialog">Cancel</CancelButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import FormField from '@/components/inputs/FormField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { deleteAccount } from '@/api/setting-routes'
import { mapActions } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'


export default {
  name: 'DialogConfirmPassword',
  inheritAttrs: false,
  components: { FormField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    loading: false,
    validForm: true,
    formData: {
      password: null,
    },
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    ...mapActions({
      deleteAccountFromState: authTypes.actions.REMOVE_ACCOUNT_DATA,
    }),
    async submitForm() {
      await this.formValidation()

      if (this.$refs.form.validate()) {
        this.loading = true

        const { success, payload, message } = await deleteAccount(this.formData)

        if (success) {
          this.$notify({ type: 'success-bg', text: message })
          this.deleteAccountFromState()
          this.$router.push({ name: 'AuthLogin' })
        } else {
          !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
        }

        this.loading = false
      }
    },
    formValidation() {
      this.rules = [
        v => !!v ||  'This field is required'
      ]
    },
    closeDialog() {
      this.show = false
    },
  },
  watch: {
    show(value) {
      if (!value) {
        this.rules = []
        this.$refs.form.reset()
        this.loading = false
      }
    }
  },
}
</script>
